import { ChainId } from '@pancakeswap/sdk'

export const GRAPH_API_PROFILE = 'https://api.thegraph.com/subgraphs/name/pancakeswap/profile'
export const GRAPH_API_PREDICTION_BNB = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction-v2'
export const GRAPH_API_PREDICTION_CAKE = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction-cake'

export const GRAPH_API_LOTTERY = 'https://api.thegraph.com/subgraphs/name/pancakeswap/lottery'
export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const API_PROFILE = 'https://profile.pancakeswap.com'
export const API_NFT = 'https://nft.pancakeswap.com/api/v1'
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`
export const GRAPH_API_POTTERY = 'https://api.thegraph.com/subgraphs/name/pancakeswap/pottery'

/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction'

export const INFO_CLIENT_PANCAKE = 'https://api.thegraph.com/subgraphs/name/vmatskiv/pancakeswap-v2'
export const INFO_CLIENT_UNI = 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v2-dev'

export const PANCAKE_SUBGRAPH = 'https://api.studio.thegraph.com/query/68306/pancake-v2/v0.0.1'
export const UNI_SUBGRAPH = 'https://api.studio.thegraph.com/query/68306/uniswap-v2/version/latest'

export const BLOCKS_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks'
export const BLOCKS_CLIENT_ETH = 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks'
export const STABLESWAP_SUBGRAPH_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-stableswap'
export const GRAPH_API_NFTMARKET = 'https://api.thegraph.com/subgraphs/name/pancakeswap/nft-market'
export const GRAPH_HEALTH = 'https://api.thegraph.com/index-node'

export const TC_MOBOX_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v3'
export const TC_MOD_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v4'

export const FARM_API = 'https://farms.pancake-swap.workers.dev'

export const BIT_QUERY = 'https://graphql.bitquery.io'

export const ACCESS_RISK_API = 'https://red.alert.pancakeswap.com/red-api'

export const CELER_API = 'https://api.celerscan.com/scan'

export const ENDPOINT_GRAPHQL_WITH_CHAIN = {
  [ChainId.BSC]: 'https://api.studio.thegraph.com/query/68306/bsc-subgraph/version/latest',
  [ChainId.ETHEREUM]: 'https://api.studio.thegraph.com/query/68306/eth-subgraph/version/latest',
  [ChainId.POLYGON]: 'https://api.thegraph.com/subgraphs/name/xoxlabs-io/polygon-subgraph',
  [ChainId.ARBITRUM]: 'https://api.thegraph.com/subgraphs/name/xoxlabs-io/arbitrum-subgraph',
  [ChainId.OPTIMISM]: 'https://api.thegraph.com/subgraphs/name/xoxlabs-io/optimism-subgraph',
  [ChainId.ZKSYNC]: 'https://api.studio.thegraph.com/query/43777/zksync-xoxlabs-subgraph/v0.0.1',
  [ChainId.BSC_TESTNET]: 'https://api.thegraph.com/subgraphs/name/xoxlabs-io/tbsc-xoxlabs',
  [ChainId.GOERLI]: 'https://api.thegraph.com/subgraphs/name/xoxlabs-io/tbsc-xoxlabs',
  [ChainId.POLYGON_TESTNET]: 'https://api.thegraph.com/subgraphs/name/xoxlabs-io/tbsc-xoxlabs',
  [ChainId.BLAST]: 'https://api.studio.thegraph.com/query/53499/xoxlabs-subraph-blast/v0.0.1',
  [ChainId.BLAST_TESTNET]: 'https://api.studio.thegraph.com/query/43777/xox-testnet-blast/v0.0.1',
}

export const INFO_CLIENT_WITH_CHAIN = {
  [ChainId.BSC]: 'https://pancakeswap.nodereal.io/graphql/',
  [ChainId.ETHEREUM]: ENDPOINT_GRAPHQL_WITH_CHAIN[1],
}

export const BLOCKS_CLIENT_WITH_CHAIN = {
  [ChainId.BSC]: BLOCKS_CLIENT,
  [ChainId.ETHEREUM]: BLOCKS_CLIENT_ETH,
}
